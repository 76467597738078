import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import SubscribeForm from '../../../components/SubscribeForm';
import { baseIBEURL } from '../../../configs';
import TrustpilotHorizontal from '../../../components/TrustpilotHorizontal';
import SearchForm from '../../../components/FindFlightsBlock/SearchForm/SearchForm';
import { useWindowSize } from '../../../helpers/useWindowSize';
import { callTrackingApiUrl } from '../../../configs';

import './index.css';
const URL =
  'https://www.googleapis.com/blogger/v3/blogs/8365748950224941809/posts/';
const Key = 'AIzaSyBzpjotWNcGTLk9faGHBrBi9Ta-xkB0LH4';

const getCurrentBlogPost = () => {
  let blogPostLink =
    typeof window !== 'undefined' && sessionStorage.getItem('currentBlogPost');
  return blogPostLink;
};

const BlogPost = ({ location }) => {
  const [postInfo, setPostInfo] = useState(null);
  const [isBannersShown, setIsBannersShown] = useState(false);
  const [ipAction, setIpAction] = useState();
  const [loading, setLoading] = useState(false);
  let postLink = getCurrentBlogPost();

  // if no postLink is present, then use hash from URL
  if (!postLink) {
    postLink = URL + location.hash.slice(1);
    typeof window !== 'undefined' &&
      sessionStorage.setItem('currentBlogPost', postLink);
  }

  const fetchPostData = async postLink => {
    const url = postLink + '?key=' + Key;
    const options = {
      method: 'GET',
      headers: {
        'content-Type': 'application/json'
      }
    };
    setLoading(true);
    await fetch(url, options)
      .then(res => res.json())
      .then(res => {
        setPostInfo(res);
        setLoading(false);
      });

    return;
  };

  const fetchBannerDetials = async () => {
    const response = await fetch(`${callTrackingApiUrl}/call-tracking/banner`);
    const responseJson = await response.json();

    setIsBannersShown(responseJson.data.show);
    setIpAction(responseJson.data.ipAction);
  };

  useEffect(() => {
    fetchPostData(postLink);
    fetchBannerDetials();
  }, [postLink]);

  const width = useWindowSize()[0];

  const pageContext = postInfo
    ? {
        title: postInfo.title,
        author: postInfo.author.displayName,
        published: postInfo.published,
        content: postInfo.content,
        id: postInfo.id
      }
    : '';
  return (
    <Layout
      isStaticPage
      passPhoneNumber={phoneNum => phoneNum}
      isBannersShown={isBannersShown}
      ipAction={ipAction}
    >
      {loading ? (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        ''
      )}
      {pageContext && (
        <div className="blog-page-container">
          <div className="main-item">
            <div className="main-item__title">
              <h1>{pageContext.title}</h1>
            </div>
            <div className="main-item__author-date">
              <span className="author">by {pageContext.author}</span>
              <br />
              <span className="date">
                {new Date(pageContext.published)
                  .toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                  })
                  .replace(/ /g, '-')}
              </span>
            </div>
            <div className="main-item__body">
              <p dangerouslySetInnerHTML={{ __html: pageContext.content }} />
            </div>
          </div>

          <div className="aside-item">
            {width >= 1024 && (
              <div className="sidebar sidebar-blog">
                <p className="title">Find cheap flights to any destination</p>
                <SearchForm baseIBEURL={baseIBEURL} updatePhone={() => {}} />
                <SubscribeForm smallSize />
              </div>
            )}
          </div>
        </div>
      )}
      {width < 1024 && pageContext && (
        <div className="search-subscribe-form">
          <p className="title">Find cheap flights to any destination</p>
          <SearchForm baseIBEURL={baseIBEURL} updatePhone={() => {}} />
          <SubscribeForm smallSize />
        </div>
      )}
      <TrustpilotHorizontal />
    </Layout>
  );
};

export default BlogPost;
